import { APP_ORIGINS } from '@eversity/domain/constants';

/**
 * Check if user operating system is Mac
 *
 * @returns boolean
 */
export const isUserOnMacOS = () => {
  return navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
};

/**
 * Return APP_ORIGINS from user referer URL.
 * @param hostname Property referer in headers request
 * @returns APP_ORIGINS
 */
export const getAppOrigin = (hostname: string | null): APP_ORIGINS => {
  const subdomain = hostname?.split('.')[0];

  if (!subdomain) {
    return APP_ORIGINS.OTHER;
  }

  switch (subdomain.toLowerCase()) {
    case 'app':
      return APP_ORIGINS.WEB;
    case 'bo':
      return APP_ORIGINS.BACKOFFICE;
    case 'tuteur':
      return APP_ORIGINS.TUTOR;
    default:
      return APP_ORIGINS.OTHER;
  }
};
