import { useQuery } from '@tanstack/react-query';

import { type FEATURE_FLAGS } from '@eversity/domain/constants';
import { UsersRepository } from '@eversity/domain/data/repositories';
import {
  type EmployeeViewFull,
  type UserFeatureFlag,
} from '@eversity/types/domain';
import { type Nullable } from '@eversity/types/misc';
import { type UseQueryParams } from '@eversity/types/web';

import http from '../../../services/http/api';

const usersRepository = new UsersRepository(http);

export const useUser = (useQueryParams?: UseQueryParams<EmployeeViewFull>) =>
  useQuery({
    queryKey: ['user'],
    queryFn: () => usersRepository.getCurrentUser(),
    ...useQueryParams,
  });

export const useUserMails = (
  labelId: string,
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getUserMails']>>
  >,
) =>
  useQuery({
    queryKey: ['userMails', labelId],
    queryFn: () => usersRepository.getUserMails(labelId),
    ...useQueryParams,
  });

export const useUserFeatureFlags = (
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getCurrentUserFeatureFlags']>>
  >,
) =>
  useQuery({
    queryKey: ['user-feature-flags'],
    queryFn: () => usersRepository.getCurrentUserFeatureFlags(),
    staleTime: 10 * 60 * 1000,
    ...useQueryParams,
  });

export const useUserFeatureFlag = (
  featureFlag: FEATURE_FLAGS,
): Nullable<{
  isLoading: boolean;
  data: UserFeatureFlag;
}> => {
  const { data: featureFlags, isLoading } = useUserFeatureFlags();

  return {
    isLoading,
    data: (featureFlags || []).find(({ name }) => name === featureFlag) || null,
  };
};
